import { Currency, Pair, Token } from '@beraswap-dex/sdk'
import {
  Button,
  //  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  MetamaskIcon,
  Down,
} from 'packages/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { isMobile } from 'react-device-detect'
import { FlexGap } from 'components/Layout/Flex'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  height: auto;
  background: transparent;
  padding: 10px;
  min-width: 100px;

  justify-content: flex-start;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: auto;
  }
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
  }
`
const InputPanel = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 1;
  width: 100%;
`
const Container = styled.div`
  border-radius: 16px;
  background-color: transparent;
  width: 100%;
`

const StyledBox = styled(Box)`
  background: transparent;
  padding: 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.xxs} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
  }
`

const StyledButton = styled(Button)`
  font-weight: 700;
  color: white;
  background: transparent;
  font-size: 14px;
  border: none;
  box-shadow: none;
  padding: 5px;

  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: unset;
  }

  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: unset;
    transform: translateY(1px);
    box-shadow: none;
  }
`

const SelectButton = styled(Flex)`
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.xxs} {
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: end;
    justify-content: space-between;
  }
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  showPercentButton?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  showPercentButton = true,
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  const balance = parseFloat(selectedCurrencyBalance?.toSignificant(6))

  const onPercent = (percent) => {
    if (percent >= 0 && percent <= 1 && balance !== undefined) {
      const percentValue = balance * percent
      onUserInput(percentValue.toString())
    }
  }

  const textSelect = isMobile ? 'Select' : 'Select a currency'

  return (
    <StyledBox position="relative" id={id}>
      <Flex width={isMobile ? '100%' : '100%'} alignItems="start" flexDirection="column">
        <Flex
          flexDirection="column"
          width={isMobile ? '100%' : '100%'}
          style={{
            background: `${
              id === 'remove-liquidity-tokena' || id === 'remove-liquidity-tokenb' || id === 'liquidity-amount'
                ? 'var(--primary-icon)'
                : 'transparent'
            }`,
            borderRadius: `${
              id === 'remove-liquidity-tokena' || id === 'remove-liquidity-tokenb' || id === 'liquidity-amount'
                ? '10px'
                : 'none'
            }`,

            border: `${
              id === 'remove-liquidity-tokena' || id === 'remove-liquidity-tokenb' || id === 'liquidity-amount'
                ? '1px solid var(--icon-button-color)'
                : 'none'
            }`,
          }}
        >
          <SelectButton>
            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              <Flex alignItems="flex-end">
                {pair &&
                id !== 'remove-liquidity-tokena' &&
                id !== 'remove-liquidity-tokenb' &&
                id !== 'liquidity-amount' ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency &&
                  id !== 'remove-liquidity-tokena' &&
                  id !== 'remove-liquidity-tokenb' &&
                  id !== 'liquidity-amount' ? (
                  <CurrencyLogo currency={currency} size="54px" style={{ marginRight: '5px' }} />
                ) : null}
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                  <Flex flexDirection="column" mr="5px">
                    <Text color="white" small bold>
                      {label}
                    </Text>

                    {pair ? (
                      <Text color="white" id="pair" bold>
                        {pair?.token0.symbol}-{pair?.token1.symbol}
                      </Text>
                    ) : (
                      <Text textAlign="left" fontSize="20px" color="white" id="pair" bold>
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                              currency.symbol.length - 5,
                              currency.symbol.length,
                            )}`
                          : currency?.symbol) || t(`${textSelect}`)}
                      </Text>
                    )}
                  </Flex>

                  {!disableCurrencySelect &&
                    id !== 'remove-liquidity-tokena' &&
                    id !== 'liquidity-amount' &&
                    id !== 'remove-liquidity-tokenb' && <Down mt="auto" mb="12px" />}
                </Flex>

                {token && tokenAddress ? (
                  <Flex style={{ gap: '5px', marginLeft: '5px' }} mb="5px" alignItems="center">
                    <CopyButton
                      width="16px"
                      buttonColor="white"
                      text={tokenAddress}
                      tooltipMessage={t('Token address copied')}
                      tooltipTop={-20}
                      tooltipRight={40}
                      tooltipFontSize={12}
                    />
                    {library?.provider?.isMetaMask && (
                      <MetamaskIcon
                        style={{ cursor: 'pointer' }}
                        width="16px"
                        onClick={() =>
                          registerToken(
                            tokenAddress,
                            token.symbol,
                            token.decimals,
                            token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                          )
                        }
                      />
                    )}
                  </Flex>
                ) : null}
              </Flex>
            </CurrencySelectButton>

            {showPercentButton && account && currency && (
              <Flex mb="10px" mt="auto" alignItems="center">
                {!isMobile ? (
                  <StyledButton onClick={() => onPercent(0.25)} scale="xs">
                    25%
                  </StyledButton>
                ) : null}

                <StyledButton onClick={() => onPercent(0.5)} scale="xs">
                  50%
                </StyledButton>
                <StyledButton onClick={() => onPercent(0.75)} scale="xs">
                  75%
                </StyledButton>
                <StyledButton
                  style={{
                    display: `${
                      id === 'remove-liquidity-tokena' || id === 'remove-liquidity-tokenb' || id === 'liquidity-amount'
                        ? 'none'
                        : 'flex'
                    }`,
                  }}
                  onClick={() => onPercent(1)}
                  scale="xs"
                >
                  {t('Max')}
                </StyledButton>
              </Flex>
            )}
          </SelectButton>

          <InputPanel>
            <Container>
              <LabelRow style={{ position: 'relative' }}>
                <NumericalInput
                  className="token-amount-input"
                  value={value}
                  onUserInput={(val) => {
                    onUserInput(val)
                  }}
                  style={{
                    borderRadius: `${
                      id === 'remove-liquidity-tokena' || id === 'remove-liquidity-tokenb' || id === 'liquidity-amount'
                        ? '0 0 10px 10px'
                        : 'none'
                    }`,
                  }}
                />
                {showMaxButton ? (
                  <StyledButton
                    style={{
                      display: `${
                        id === 'remove-liquidity-tokena' ||
                        id === 'remove-liquidity-tokenb' ||
                        id === 'liquidity-amount'
                          ? 'block'
                          : 'none'
                      }`,
                      position: 'absolute',
                      top: '50%',
                      right: '10px',
                      transform: 'translate(-10%, -10px)',
                      // border: '1px solid #7A72AF',
                      borderRadius: '10px',
                      // background: '#4D4487CC',
                      padding: '0 5px',
                    }}
                    onClick={() => onPercent(1)}
                    scale="xs"
                  >
                    {t('Max')}
                  </StyledButton>
                ) : null}
              </LabelRow>
            </Container>
          </InputPanel>
        </Flex>

        <FlexGap width="100%" justifyContent="space-between" mt="10px">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {account ? (
              <Text
                ml="8px"
                onClick={onMax}
                color="white"
                small
                fontStyle="italic"
                style={{ display: 'inline', cursor: 'pointer', whiteSpace: 'nowrap' }}
              >
                {!hideBalance && !!currency
                  ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                  : null}
              </Text>
            ) : (
              <Text />
            )}
          </Flex>
        </FlexGap>
      </Flex>
    </StyledBox>
  )
}
