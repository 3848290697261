import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@beraswap-dex/sdk'
import styled from 'styled-components'
import {
  Button,
  Text,
  ErrorIcon,
  ArrowUpIcon,
  MetamaskIcon,
  Flex,
  Box,
  Link,
  Spinner,
  Modal,
  InjectedModalProps,
  Confirmed,
  LinkExternal,
} from 'packages/uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from 'state/types'
import { RowFixed } from '../Layout/Row'
import { AutoColumn, ColumnCenter } from '../Layout/Column'
import { getBscScanLink } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 0;
`

const ConfirmedIcon = styled(ColumnCenter)`
  width: auto;
  margin: auto;
  border-radius: 100%;
`

const StyledFlex = styled(Flex)`
  justify-content: center;
  padding: 24px 0;
`

const StyledModal = styled(Modal)``

function ConfirmationPendingContent({ pendingText, onDismiss }: { pendingText: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn gap="12px" justify="center">
        <Text fontSize="30px" textAlign="center">
          {t('Waiting For Confirmation')}
        </Text>
        <AutoColumn gap="12px" justify="center">
          <Text fontSize="16px" fontStyle="italic" textAlign="center">
            {pendingText}
          </Text>
        </AutoColumn>
      </AutoColumn>
      <StyledFlex>
        <Spinner />
      </StyledFlex>

      <Text fontStyle="italic" fontWeight="500" textAlign="center">
        {t('Confirm this transaction in your wallet')}
      </Text>

      <Button width="100%" onClick={onDismiss} mt="20px">
        {t('Close')}
      </Button>
    </Wrapper>
  )
}

export function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const { library } = useActiveWeb3React()

  const { t } = useTranslation()

  const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

  return (
    <Wrapper>
      <Section style={{ gap: '20px' }}>
        <Text textAlign="center" fontSize="30px">
          Successful Transaction
        </Text>
        <ConfirmedIcon>
          <Confirmed strokeWidth={0.5} width="90px" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          {/* <StyledText Submitted fontSize="20px" bold>
            {t('Transaction Submitted')}
          </StyledText> */}
          {chainId && hash ? (
            <Button variant="link" height="43px" as={LinkExternal} href={getBscScanLink(hash, 'transaction', chainId)}>
              <Text fontSize="18px">{t('View on Explorer')}</Text>
            </Button>
          ) : null}

          {/* {chainId && hash && <Link external small href={getBscScanLink(hash, 'transaction', chainId)}></Link>} */}
          {/* {currencyToAdd && library?.provider?.isMetaMask && (
            <Button
              style={{ background: '#FDCEE5', color: 'black' }}
              mt="12px"
              width="fit-content"
              onClick={() =>
                registerToken(
                  token.address,
                  token.symbol,
                  token.decimals,
                  token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                )
              }
            >
              <RowFixed>
                {t('Add %asset% to Metamask', { asset: currencyToAdd.symbol })}
                <MetamaskIcon width="16px" ml="6px" />
              </RowFixed>
            </Button>
          )} */}
          <Button width="100%" onClick={onDismiss} mt="20px">
            {t('Close')}
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center">
        <ErrorIcon color="white" width="64px" />
        <Text color="white" style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}>
          {message}
        </Text>
      </AutoColumn>

      <Flex justifyContent="center" pt="24px">
        <Button onClick={onDismiss} width="100%">
          {t('Dismiss')}
        </Button>
      </Flex>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}) => {
  const { chainId } = useActiveWeb3React()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} headerBackground="gradients.cardHeader" onDismiss={handleDismiss} bodyPadding="0 24px 24px">
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
