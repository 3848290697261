import React from 'react'
import styled, { keyframes } from 'styled-components'
import { SpinnerProps } from './types'
import { BlastLoader } from '../Svg'

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Basic = styled.div`
  &::before {
    content: '';
    display: block;
    height: 150px;
    width: 150px;
    animation: ${spin} 1s infinite linear;
    border: 10px solid #380324;
    border-left-color: #e81899;
    border-radius: 100%;
  }
`

const Container = styled.div``

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <Container>
      <img src="/images/spinner.gif" alt="" />
    </Container>
  )
}

export default Spinner
